<script setup lang="ts">
import { FormalInput } from 'webcc-ui-components'

withDefaults(
  defineProps<{
    name: string
    label: string
    validationMessages: Record<string, string>
    validation?: string
    showValid?: boolean
  }>(),
  {
    validation: 'required',
    showValid: false,
  },
)

defineEmits<{
  (e: 'focus', name: string): void
  (e: 'blur', name: string): void
}>()

const [showPasswordAsPlainText, toggleShowPasswordAsPlainText] = useToggle()
</script>

<template>
  <FormalInput
    :name="name"
    :label="label"
    :validation-messages="validationMessages"
    :validation="validation"
    :show-valid="showValid"
    :type="showPasswordAsPlainText ? 'text' : 'password'"
    :action-button="showPasswordAsPlainText ? 'eye-off' : 'eye'"
    v-bind="$attrs"
    @formkit-action="toggleShowPasswordAsPlainText"
    @blur="$emit('blur', name)"
    @focus="$emit('focus', name)"
  />
</template>
